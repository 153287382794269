















import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    UserSettingsSidebar: () => getComponent('common/UserSettingsSidebar'),
  },
})
export default class UserSettings extends Vue {
  async created() {
    // Temporary solution, need to find a better way to do this
    if (!location.href.includes('/settings/')) {
      this.$router.push({ name: 'UserProfile' });
    }
  }
}
